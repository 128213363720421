import { partial } from "@lib/fn";

const load = (modal, content) => {
  content.remove();

  content.removeAttribute("data-target");

  modal.querySelector(".modal__body").append(content);
};

const build = () => {
  const modal = buildComponent();

  const mask = buildComponent("mask");
  const body = buildComponent("body");

  mask.addEventListener("click", partial(dismiss, modal));

  modal.append(mask, body);

  return modal;
};

const buildComponent = type => {
  const element = document.createElement("div");

  element.classList.add(type ? `modal__${type}` : "modal");

  return element;
};

const dismiss = modal => {
  modal.addEventListener("transitionend", modal.remove, { once: true });

  modal.classList.remove("active");
};

const show = async modal => {
  document.querySelector("body").appendChild(modal);

  /*
   * NB: (GR) Without the 1ms "sleep" below the CSS animations won't occur and
   *     the modal will "pop" in.
   */
  await new Promise(r => setTimeout(r, 1));

  modal.classList.add("active");
};

export default (() => {
  const instance = build();

  return {
    dismiss: partial(dismiss, instance),
    show: partial(show, instance),
    load: partial(load, instance)
  };
})();
