import Rails from "@rails/ujs";

const postEmail = (e) => {
  e.preventDefault();

  const url = e.target.getAttribute("data-url");
  Rails.ajax({
    url: url,
    type: "POST",
    success: () => {
      document.querySelector("#request_emailed").style.display = "block";
    },
    error: () => {
      document.querySelector("#request_emailed").style.display = "none";
    },
  });
  // Note to self, the below should only be done after the event has been successfully submitted.
  // document.querySelector("[id=\"email_quote\"]").disabled = true
};

export default () => {
  const selector = document.querySelector("#email_request");

  selector.addEventListener("click", postEmail);
};
