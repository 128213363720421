/* global Stripe */
import config from "@app/config";
import email from "@app/components/common/email";
import discountModal from "./discountModal";
import Rails from "@rails/ujs";

const stripe = Stripe(config.stripePublicKey);
const clientSecret = document.querySelector("#payment-intent-info").dataset.clientSecret;
let card, auBankAccount;

const handleCardButton = () => {
  const input_containers = document.querySelectorAll("li.binary-choice__option");
  const inputs = document.querySelectorAll("input[type=radio]");
  const payment_forms = document.querySelectorAll(".payment-container");

  function hideForm() {
    payment_forms.forEach((form) => {
      form.style.display = "none";
      form.querySelectorAll("input").forEach((input) => {
        input.disabled = true;
      });
    });
    document.querySelector(".non-selected-image").style.display = "block";
    document.querySelector(".selected-image").style.display = "none";
  }

  input_containers.forEach((input_container) => {
    input_container.addEventListener("click", function () {
      this.querySelector("input[type=radio]").click();
    });
  });

  inputs.forEach((input) => {
    input.addEventListener("change", function () {
      hideForm();
      const container = document.querySelector(`.${this.value}-form-container`);
      if (container === null) {
        return;
      }
      container.style.display = "block";
      container.querySelectorAll("input").forEach((input) => {
        input.disabled = false;
      });
      if (this.value === "debit") {
        document.querySelector(".non-selected-image").style.display = "none";
        document.querySelector(".selected-image").style.display = "block";
      }
    });
  });
};

// Fetches a payment intent and captures the client secret
async function initialize() {
  const style = {
    base: {
      fontSize: "18px",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      "::placeholder": {
        backgroundColor: "#fff",
      },
    },
  };

  const options = {
    style: style,
    disabled: false,
    hideIcon: false,
    iconStyle: "default", // or "solid"
  };
  const elements = stripe.elements();
  card = elements.create("card", options);
  card.mount("#card-payment-element");
  auBankAccount = elements.create("auBankAccount", options);
  auBankAccount.mount("#au-bank-account-element");
}

async function handleSubmit(e) {
  e.preventDefault();
  document.querySelector("button[type=submit]").disabled = true;

  const nameInput = document.querySelector("#name");
  const emailInput = document.querySelector("#email");
  const selectedValue = document.querySelector("input[name=payment-selection]:checked").value;
  const returnUrl = document.querySelector("#payment-form").getAttribute("data-url");
  const codUrl =
    document.querySelector("input[name=payment-selection]:checked").getAttribute("data-url") +
    ".json";

  if (selectedValue === "cod") {
    Rails.ajax({
      url: codUrl,
      type: "POST",
      success: function (_data) {
        window.location.href = returnUrl;
      },
      error: function (data) {
        document.querySelector("button[type=submit]").disabled = false;
        showMessage(data.errors[0].detail);
      },
    });
  } else {
    // Confirm the card payment given the clientSecret
    // from the payment intent that was just created on
    // the server.
    const { error } = await (selectedValue === "debit"
      ? stripe.confirmAuBecsDebitPayment(clientSecret, {
          payment_method: {
            au_becs_debit: auBankAccount,
            billing_details: {
              name: nameInput.value,
              email: emailInput.value,
            },
          },
          return_url: returnUrl,
        })
      : stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              name: nameInput.value,
            },
          },
          return_url: returnUrl,
        }));

    document.querySelector("button[type=submit]").disabled = false;
    if (error) {
      showMessage(error.message);
    } else {
      window.location.href = returnUrl;
    }
  }
}

// ------- UI helpers -------

function showMessage(messageText) {
  const messageContainer = document.querySelector("#payment-message");

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

  setTimeout(function () {
    messageContainer.classList.add("hidden");
    messageContainer.textContent = "";
  }, 4000);
}

export default () => {
  initialize();
  handleCardButton();
  email();
  discountModal();

  document.querySelector("#payment-form").addEventListener("submit", handleSubmit);
};
